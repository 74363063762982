import * as React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { AnnouncementHeading } from "components/AnnouncementHeading";

type Props = {
    announcements: Queries.HomeQuery["announcements"];
};

export const HomeAnnouncements: React.FC<Props> = ({ announcements }) => {
    const { t } = useTranslation();
    return (
        <div className="max-w-wide mx-auto page-trailer">
            <AnnouncementHeading as="h2" />
            <div className="pt-7 md:pt-28 bg-gradient-100 md:bg-gradient-200 px-5 font-bold flex flex-col items-center">
                <div className="md:grid md:grid-cols-3 md:gap-14 max-w-closet mx-auto w-full">
                    {announcements.edges.map(({ node }) => (
                        <section key={node.id} className="mb-4  flex flex-col">
                            <time className="block text-sm lg:text-2xl italic mb-4">
                                {node.frontmatter?.published}
                            </time>
                            <div className="flex flex-col grow border-y-5 lg:border-y-10 py-6 mb:pb-4">
                                <h3 className="mb-6 lg:mb-12 lg:text-2xl grow">
                                    {node.frontmatter?.title}
                                </h3>
                                <Link
                                    to={`/News/${node.frontmatter?.slug}`}
                                    className="inline-block uppercase ml-auto lg:text-xl text-white italic bg-black py-1 lg:py-2 px-2.5 lg:px-5"
                                >
                                    {t("button.read_more")}-
                                </Link>
                            </div>
                        </section>
                    ))}
                </div>
                <Link
                    to="/News"
                    className="mt-7 mb-11 md:mt-32 md:mb-20 uppercase italic text-white bg-black text-2xl lg:text-3.5xl py-4 lg:py-6 px-6 lg:px-24"
                >
                    {t("button.view_all")}
                </Link>
            </div>
        </div>
    );
};
