import React from "react";
import cx from "classnames";

type Props = {
    area?: NonNullable<
        NonNullable<Queries.CharacterQuery["character"]>["frontmatter"]
    >["area"];
} & React.HTMLAttributes<HTMLDivElement>;

const BG: Record<string, string> = {
    modern: "bg-brown-100",
    light: "bg-yellow-100",
    water: "bg-blue-100",
    wood: "bg-green",
    death: "bg-purple-300",
    fire: "bg-brown-200",
};

export const AreaBackgroundCard: React.FC<Props> = ({
    area,
    className,
    ...props
}) => {
    return <div className={cx(BG[area ?? "modern"], className)} {...props} />;
};
