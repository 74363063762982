import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import type SwiperClass from "swiper/types/swiper-class";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { useMediaQuery } from "@react-hookz/web";
import { StrokeHeading } from "components/StrokeHeading";
import { AreaBackgroundCard } from "components/AreaBackgroundCard";
import { VerticalLine } from "components/VerticalLine";
import { ReactComponent as DoubleArrow } from "icons/double-arrow-left.svg";
import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg";

type Props = {
    characters: Queries.HomeQuery["characters"];
};

function SlideItem(props: {
    node: Props["characters"]["edges"][number]["node"];
}) {
    const { isActive } = useSwiperSlide();
    const image =
        props.node.frontmatter?.swiperImage?.childImageSharp?.gatsbyImageData;

    if (!image) return null;

    return (
        <GatsbyImage
            alt={props.node.frontmatter?.title ?? ""}
            image={image}
            loading="eager"
            className={cx("w-full max-w-[269px] lg:max-w-[571px]", {
                invisible: !isActive,
            })}
        />
    );
}

function Heading() {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <div className="flex justify-center relative z-10 lg:justify-start lg:grid lg:grid-cols-12 lg:mb-[max(-168px,-11vw)]">
            <StrokeHeading as="h2" className="lg:col-start-2">
                Character
            </StrokeHeading>
        </div>
    );
}

export const HomeCharacters: React.FC<Props> = ({ characters }) => {
    const { t } = useTranslation();
    const isDesktop = useMediaQuery("(min-width: 1024px)", true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState<boolean>(false);
    const [swiper, setSwiper] = useState<SwiperClass>();
    const activeCharacter = characters.edges[activeIndex].node;
    const activeMessages = activeCharacter.frontmatter?.message?.split("  \n");
    const startTransition = () => setAnimating(true);
    const endTransition = (swiper: SwiperClass) => {
        setActiveIndex(swiper.realIndex);
        setAnimating(false);
    };
    const handleNext = () => swiper?.slideNext();
    const handlePrev = () => swiper?.slidePrev();

    return (
        <>
            <div className="bg-[url(/images/character-aura.svg)] bg-no-repeat bg-bottom bg-[length:180vw] md:bg-cover lg:bg-contain lg:pb-6 lg:pt-24">
                <Heading />
                <div className="grid grid-rows-12 grid-cols-1 mx-auto lg:max-w-wide lg:grid-cols-12">
                    <div
                        className="
                        px-4 col-span-full row-start-1 row-span-9 -mt-2 lg:col-start-2 lg:col-span-10
                        lg:row-start-2 lg:row-span-7 lg:mt-6 lg:px-0 lg:relative
                    "
                    >
                        <AreaBackgroundCard
                            area={activeCharacter.frontmatter?.area}
                            className="transition h-full transform -skew-y-[5deg] lg:transform-none"
                        />
                        <span className="hidden absolute -right-16 -bottom-14 text-[82px] font-bold lg:inline-block">
                            /////////////////
                        </span>
                    </div>
                    <div
                        className="
                        col-span-full row-start-3 row-span-9 lg:col-start-5 lg:col-span-7 xl:col-start-6 xl:col-span-6
                        lg:row-span-full
                    "
                    >
                        <div className="mx-auto max-w-[413px] lg:max-w-[903px] lg:mr-0">
                            <Swiper
                                loop
                                centeredSlides
                                loopAdditionalSlides={1}
                                onSwiper={setSwiper}
                                onSlideChangeTransitionStart={startTransition}
                                onSlideChangeTransitionEnd={endTransition}
                                spaceBetween={-197}
                                slidesPerView={2.2}
                                breakpoints={{
                                    1024: {
                                        spaceBetween: -405,
                                        slidesPerView: 2,
                                    },
                                    1920: {
                                        spaceBetween: -405,
                                        slidesPerView: 3,
                                    },
                                }}
                                className="[&_.swiper-slide-next]:-z-10 [&_.swiper-slide-prev]:-z-10"
                            >
                                {characters.edges.map(({ node }) => (
                                    <SwiperSlide key={node.id}>
                                        <SlideItem node={node} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <section
                        className={cx(
                            "transition-opacity px-8 pb-4 row-span-full col-span-full z-10 pointer-events-none",
                            "flex flex-col items-start lg:col-start-2 lg:col-span-5 lg:row-start-3 lg:row-span-8",
                            "lg:px-12 lg:pb-[length:min(54px,3.3vw)]",
                            {
                                "opacity-0": animating,
                            }
                        )}
                    >
                        <div className="pl-10 mt-4">
                            <h3 className="text-3.5xl lg:text-7xl lg:mb-4">
                                {activeCharacter.frontmatter?.title}
                            </h3>
                            <h4 className="text-2xl lg:text-3.5xl">
                                CV:{activeCharacter.frontmatter?.cv}
                            </h4>
                        </div>
                        <div className="mt-auto mb-14 space-y-0.5 lg:mb-12">
                            {activeMessages?.map((paragraph, idx) => (
                                <p key={idx}>
                                    <span className="text-white bg-black px-2 text-xl leading-[length:1.7] lg:text-4xl font-bold lg:leading-[length:1.7]">
                                        {paragraph}
                                    </span>
                                </p>
                            ))}
                        </div>
                        <Link
                            to={`/Characters/${activeCharacter.frontmatter?.slug}`}
                            className="pointer-events-auto italic text-white font-bold bg-purple-200 py-1.5 px-4"
                        >
                            {t("button.read_more")}-
                        </Link>
                    </section>
                    <button
                        type="button"
                        onClick={handlePrev}
                        className="
                        col-start-1 row-start-1 row-span-2 justify-self-start z-10 pl-2 mt-4
                        lg:col-start-1 lg:col-span-1 lg:row-start-4
                    "
                    >
                        {isDesktop ? (
                            <ArrowLeft width={64} height={134} />
                        ) : (
                            <DoubleArrow width={57} height={57} />
                        )}
                    </button>
                    <button
                        type="button"
                        onClick={handleNext}
                        className="
                        col-start-1 row-start-11 row-span-2 z-10 justify-self-end pr-2
                        lg:col-start-12 lg:col-span-1 lg:row-start-4
                    "
                    >
                        {isDesktop ? (
                            <ArrowLeft
                                width={64}
                                height={134}
                                className="transform rotate-180"
                            />
                        ) : (
                            <DoubleArrow
                                width={57}
                                height={57}
                                className="transform rotate-180"
                            />
                        )}
                    </button>
                </div>
            </div>
            <VerticalLine className="my-4 lg:mb-32 lg:-mt-72 lg:h-80" />
        </>
    );
};
