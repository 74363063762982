import "swiper/css";
import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import type SwiperClass from "swiper/types/swiper-class";
import cx from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { StrokeHeading } from "components/StrokeHeading";
import { VerticalLine } from "components/VerticalLine";
import { ReactComponent as DoubleArrow } from "icons/double-arrow-left.svg";

type Props = {
    features: Queries.HomeQuery["features"];
};

function Heading() {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <div className="lg:grid lg:grid-cols-12 lg:px-0 px-5 bg-gradient-100 lg:bg-gradient-200 bg-[length:100%_min(20px,12vw)] bg-no-repeat lg:bg-no-repeat lg:bg-[length:100%_min(64px,3.3vw)] bg-bottom lg:bg-bottom">
            <StrokeHeading as="h2" className="lg:col-start-2">
                Game
                <br />
                Features
            </StrokeHeading>
        </div>
    );
}

function Slide(props: { node: Props["features"]["edges"][number]["node"] }) {
    const image =
        props.node.frontmatter?.thumbnail?.childImageSharp?.gatsbyImageData;

    if (!image) return null;

    return <GatsbyImage alt="Feature Image" image={image} />;
}

function Story() {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <div className="uppercase font-bold text-4xl lg:text-[110px] italic text-right md:text-left relative top-4 lg:col-start-2">
            story
        </div>
    );
}

export const HomeGameFeatures: React.FC<Props> = ({ features }) => {
    const [swiper, setSwiper] = useState<SwiperClass>();
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [animating, setAnimating] = useState<boolean>(false);
    const activeFeature = features.edges[activeIndex].node;
    const activeTitle = activeFeature.frontmatter?.title?.split("\n");
    const startTransition = () => setAnimating(true);
    const endTransition = (swiper: SwiperClass) => {
        setActiveIndex(swiper.realIndex);
        setAnimating(false);
    };
    const handleNext = () => swiper?.slideNext();
    const handlePrev = () => swiper?.slidePrev();
    return (
        <div className="mx-auto max-w-wide mb-16">
            <Heading />
            <div className="bg-gradient-100 lg:bg-gradient-200">
                <div className="lg:grid lg:grid-cols-12 lg:pt-4">
                    <button
                        type="button"
                        onClick={handlePrev}
                        className="relative left-3 top-4 z-10 lg:static lg:grid-cols-1 lg:flex lg:items-end lg:justify-end lg:pb-6 lg:pr-6"
                    >
                        <DoubleArrow className="w-14 h-14" />
                    </button>
                    <div className="lg:col-span-6 lg:pr-6 lg:mb-6">
                        <Swiper
                            loop
                            loopAdditionalSlides={1}
                            onSwiper={setSwiper}
                            onSlideChangeTransitionStart={startTransition}
                            onSlideChangeTransitionEnd={endTransition}
                        >
                            {features.edges.map(({ node }) => (
                                <SwiperSlide key={node.id}>
                                    <Slide node={node} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="px-3 lg:contents">
                        <section className="relative bottom-5 z-10 lg:col-span-5 lg:grid lg:grid-cols-12 lg:content-start lg:items-center lg:h-full lg:grid-rows-[auto_1fr]">
                            <div className="flex items-start justify-between lg:contents">
                                <h3
                                    className={cx(
                                        "lg:text-xl 2xl:text-3.5xl text-white font-bold space-y-2 flex",
                                        "flex-col items-start mr-4 lg:col-span-full lg:relative lg:right-14",
                                        "transition-opacity",
                                        {
                                            "opacity-0": animating,
                                            "lg:bottom-8":
                                                (activeTitle?.length ?? 0) > 1,
                                        }
                                    )}
                                >
                                    {activeTitle?.map((text) => (
                                        <span
                                            key={text}
                                            className="py-2 px-4 bg-black inline-block"
                                        >
                                            {text}
                                        </span>
                                    ))}
                                </h3>
                                <button
                                    type="button"
                                    onClick={handleNext}
                                    className="mt-2 lg:col-span-1 lg:mt-0"
                                >
                                    <DoubleArrow className="transform rotate-180 w-14 h-14" />
                                </button>
                            </div>
                            <p
                                className={cx(
                                    "mt-2 lg:text-lg 2xl:text-2xl lg:col-span-11 lg:px-6 lg:mt-0 transition-opacity",
                                    { "opacity-0": animating }
                                )}
                                dangerouslySetInnerHTML={{
                                    __html: activeFeature.html ?? "",
                                }}
                            />
                        </section>
                        <Story />
                    </div>
                </div>
            </div>
            <VerticalLine className="mt-12 md:mt-0" />
        </div>
    );
};
