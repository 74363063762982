import cx from "classnames";
import { ExternalLink } from "components/ExternalLink";
import { VerticalLine } from "components/VerticalLine";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useAgeWarningState } from "hooks/useAgeWarningState";
import { useDMMPopupState } from "hooks/useDMMPopupState";
import { useMetadata } from "hooks/useMetadata";
import { isDMM } from "libs/dmm";
import React, { Suspense, useState } from "react";
import { isMobile } from "react-device-detect";

import { useMediaQuery } from "@react-hookz/web";

const Video = React.lazy(() =>
    import("components/HomeBillboardVideo").then((mod) => ({
        default: mod.HomeBillboardVideo,
    }))
);

const Query = graphql`
    query HomeBillboard {
        logo: allFile(
            filter: {
                name: { eq: "logo-black" }
                sourceInstanceName: { eq: "images" }
            }
        ) {
            edges {
                node {
                    relativeDirectory
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: TRACED_SVG
                            quality: 90
                            sizes: "(max-width: 1024px) 190px, 480px"
                        )
                    }
                }
            }
        }
    }
`;

const KV = () => {
    const ALT = "Key Visual";

    return (
        <>
            <div className="hidden lg:block">
                <StaticImage
                    src="../images/kv.png"
                    alt={ALT}
                    loading="eager"
                    objectFit="contain"
                    placeholder="none"
                    className="mt-header"
                />
            </div>
            <div className="lg:hidden">
                <StaticImage
                    src="../images/kv-mobile.png"
                    alt={ALT}
                    loading="eager"
                    placeholder="none"
                    objectFit="contain"
                    className="max-h-screen"
                />
            </div>
        </>
    );
};

function Logo(props: {
    src?: Queries.HomeBillboardQuery["logo"]["edges"][number];
    className?: string;
}) {
    const metadata = useMetadata();

    if (!props.src?.node?.childImageSharp?.gatsbyImageData) return null;

    return (
        <section className={props.className}>
            <h1 className="sr-only">{metadata?.title}</h1>
            <GatsbyImage
                alt="Logo"
                image={props.src.node.childImageSharp.gatsbyImageData}
                className={cx(
                    "w-[190px]",
                    isDMM ? "lg:w-[480px]" : "lg:w-[376px]"
                )}
            />
        </section>
    );
}

const DownloadButton = () => {
    return (
        <ExternalLink
            href="http://bit.ly/3v4S2e8"
            className="mt-3 inline-block lg:mb-16"
        >
            <img
                loading="lazy"
                src="/images/download-now.svg"
                alt="Download Now"
                className="lg:w-[262px]"
            />
        </ExternalLink>
    )
}

const DMMDownloadButton = () => {
    const { t } = useI18next();
    const isDesktop = useMediaQuery("(min-width: 1024px)", true);

    return (
        <ExternalLink
            id={isDesktop ? undefined : "kv-dl"}
            href="https://ad.games.dmm.co.jp/nucarnival_r01/index.html"
            className="mt-3 inline-block lg:hidden"
        >
            <img
                loading="lazy"
                src="/images/download-now-dmm.svg"
                alt={t("nav.download")}
            />
        </ExternalLink>
    )
}

export const HomeBillboard = () => {
    const isDesktop = useMediaQuery("(min-width: 1024px)", true);
    const { logo } = useStaticQuery<Queries.HomeBillboardQuery>(Query);
    const [played, setPlayed] = useState(false);
    const { isOpen } = useAgeWarningState();
    const { setIsOpen } = useDMMPopupState();
    const { language, t } = useI18next();
    const currentLogo = logo.edges.find(
        ({ node }) => node.relativeDirectory === language
    );

    return (
        <>
            <div className="grid">
                <div className="col-span-full row-span-full">
                        <div className="relative mx-auto text-center">
                        <KV />
                        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 transform text-center lg:bottom-0 z-20">
                            {isDMM && played && <DMMDownloadButton />}
                            {isDMM && <Logo src={currentLogo} />}

                            {!isDMM && played && <DownloadButton />}
                            {/* lg logo is outside */}
                            {!isDMM && <Logo src={currentLogo} className="lg:hidden" />}
                        </div>
                        {!isDMM && (
                            <div className="absolute max-w-[1736px] w-full left-1/2 -translate-x-1/2 bottom-16 hidden lg:flex z-20">
                                <Logo
                                    src={currentLogo}
                                    className="absolute left-0 bottom-16 hidden lg:inline-block z-20"
                                />
                            </div>
                        )}

                        <div className={cx({ invisible: !played })}>
                            {isDMM && (
                                <div className="absolute left-[7vw] bottom-[5vw] hidden flex-col items-center space-y-3 lg:inline-flex">
                                    <StaticImage
                                        src="../images/android-qrcode.png"
                                        alt="QR code"
                                        width={257}
                                        height={90}
                                    />
                                    <DMMStart onClick={() => setIsOpen(true)}>
                                        <img
                                            loading="lazy"
                                            src="/images/download-now-dmm-universal.svg"
                                            alt={t("nav.download")}
                                        />
                                    </DMMStart>
                                </div>
                            )}
                        </div>
                        <div
                            className={cx(
                                "absolute w-full top-0 h-full transition-opacity [&_video]:object-cover",
                                { "pointer-events-none opacity-0": played }
                            )}
                        >
                            <div className="absolute top-0 z-10 h-[156px] w-full bg-gradient-to-b from-white" />
                            <Suspense fallback={<div />}>
                                <Video
                                    playing={!isOpen}
                                    url={isDesktop ? "/index-kv.mp4" : "/index-kv-m.mp4"}
                                    onEnded={() => setPlayed(true)}
                                />
                            </Suspense>
                            <div className="absolute bottom-[-1px] z-10 h-[294px] w-full bg-gradient-to-t from-white" />
                        </div>
                    </div>
                </div>
            </div>
            <VerticalLine className="mt-3" />
        </>
    );
};

const DMMStart = (props: {
    onClick: () => void;
    children: React.ReactNode;
}) => {
    const id = "kv-dl";

    if (isMobile)
        return (
            <ExternalLink
                id={id}
                href="https://ad.games.dmm.co.jp/nucarnival_r01/index.html"
            >
                {props.children}
            </ExternalLink>
        );

    return (
        <button id={id} type="button" onClick={props.onClick}>
            {props.children}
        </button>
    );
};
