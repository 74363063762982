import React from "react";
import cx from "classnames";

export function VerticalLine(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={cx(
                "w-0.5 h-20 bg-black lg:h-60 mx-auto",
                props.className
            )}
        />
    );
}
