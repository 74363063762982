import React, { useRef, useEffect, useState } from "react";
import { useIntersectionObserver } from "@react-hookz/web";
import cx from "classnames";

type Props = {
    className?: string;
    threshold?: number[];
    children: React.ReactNode;
};

export const Reveal = ({
    threshold = [0.3],
    children,
    className,
    ...props
}: Props) => {
    const [inView, setInView] = useState<boolean>(false);
    const Ref = useRef(null);
    const intersection = useIntersectionObserver(Ref, { threshold });

    useEffect(() => {
        if (inView) return;

        setInView(!!intersection?.isIntersecting);
    }, [intersection?.isIntersecting]);

    return (
        <div
            ref={Ref}
            className={cx(
                "transform transition duration-500",
                inView ? "translate-y-0" : "translate-y-10 opacity-0",
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
};
